import React from 'react';
import {
    Box,
    Modal, ModalBody, ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import Media from "./Media";

function Post({post}) {
    // console.log("Post", post)
    const {isOpen, onOpen, onClose} = useDisclosure()

    function onClick() {
        if (post.media_type !== 2) {
            onOpen()
        }
    }

    return (
        <Box onClick={onClick}>
            <Media media={post}/>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalCloseButton/>
                    <ModalBody>
                        {
                            post.media_type === 8 ?
                                (
                                    post.carousel_media.map((media) => (
                                        <Media key={media.id} media={media}/>
                                    ))
                                ) :
                                (<Media media={post}/>)
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>

    )
}

export default Post