import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import Posts from "./Posts";
import Stories from "./Stories";

function InstaTab({username}) {
    return (
        <Tabs defaultIndex={0} isFitted variant='enclosed'>
            <TabList  mb='1em'>
                <Tab>Stories</Tab>
                <Tab>Posts</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <Stories username={username}/>
                </TabPanel>
                <TabPanel>
                    <Posts username={username}/>
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

export default InstaTab