import {Alert, CircularProgress, SimpleGrid} from "@chakra-ui/react";
import InfiniteScroll from "react-infinite-scroller";
import Post from "./Post";
import useSWRInfinite from "swr/infinite";
import Error from "./Error";

function Stories({username}) {
    const getKey = (pageIndex, previousPageData) => {
        // reached the end
        if (previousPageData && previousPageData?.data?.items?.length < 12) return null
        const url = "https://ext-api.getghostify.com/scraper/v1/stories?username_or_id_or_url=" + username
        // first page, we don't have `previousPageData`
        if (pageIndex === 0) return url

        // add the cursor to the API endpoint
        return url + `&pagination_token=${previousPageData.pagination_token}`
    }

    const {data, error, isLoading, size, setSize} = useSWRInfinite(
        getKey
    )
    const stories = data ? [].concat(...data.map(page => page.data.items)) : [];
    const hasMore = data && data[data.length - 1]?.data?.items?.length === 12;
    const isLoadingMore = isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");

    const loadMore = () => {
        if (!isLoadingMore && hasMore) {
            setSize(size + 1);
        }
    };

    if (isLoading && size === 1) return <CircularProgress isIndeterminate color='green.300'/>
    if (error) return <Error/>
    if (stories.length === 0) return (<Alert status={"info"}>@{username} has no story in the last 24 hours</Alert>)

    return (
        <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            hasMore={hasMore}
            loadMore={loadMore}
            loader={<div className="loader" key={0}>Loading ...</div>}
        >
            <SimpleGrid columns={3} spacing={{base: 3, md: 6}}>
                {
                    stories.map(story => (
                        <Post post={story} key={story.id}/>
                    ))
                }
            </SimpleGrid>
        </InfiniteScroll>
    );
}

export default Stories