import {Avatar, Box, Text, useDisclosure, VStack} from "@chakra-ui/react";
import Highlight from "./Highlight";

function HighlightItem({highlight}) {
    const {isOpen, onOpen, onClose} = useDisclosure()

    function convertThumb(highlight) {
        const url = encodeURIComponent(highlight.cover_media.cropped_image_version.url)
        return `https://ext-api.getghostify.com/media?url=${url}`
    }

    return (
        <Box cursor={"pointer"} flexShrink={0} mr={4} key={highlight.id}>
            <VStack onClick={onOpen}>
                <Box
                    borderRadius="full"
                    background={"gray.300"}
                    p="2px"
                    display="inline-block"
                >
                    <Avatar  border="2px solid white" size='lg' src={convertThumb(highlight)}/>
                </Box>
                <Text>{highlight.title}</Text>
            </VStack>
            <Highlight visibility={isOpen ? "visible" : "hidden"} onClose={onClose} isOpen={isOpen}
                       highlightId={highlight.id}/>
        </Box>

    );
}

export default HighlightItem