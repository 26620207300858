import {Alert, AlertIcon} from "@chakra-ui/react";

function Error({message = "There was an error processing your request"}) {
    return (
        <Alert status='error'>
            <AlertIcon />
            {message}
        </Alert>
    )
}

export default Error