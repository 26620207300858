import {AspectRatio, Box, Button, Image, Link} from "@chakra-ui/react";
import React from "react";
import {Icon} from "@chakra-ui/icons";


function Media({media}) {

    function convertThumb(mediaUrl) {
        const url = encodeURIComponent(mediaUrl)
        return `https://ext-api.getghostify.com/media?url=${url}`
    }

    function onClick() {
        if (media.is_video) {
            window.open(media.video_url, '_blank').focus();
        }
    }

    return (
        <Box cursor={"pointer"} position={"relative"}>
            <>
                <Icon zIndex={1000} position="absolute"
                      top="0"
                      right="0"
                      boxSize={10}
                      m={2}
                      visibility={media.media_type === 8 ? "visible" : "hidden"}
                      viewBox='0 0 60 60'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1004.1441">
                        <path
                            fill="#fff"
                            d="m792 208.144c50 38 83 100 83 167v292c0 116-92 208-208 208h-292c-67 0-129-33-167-83h417c92 0 167-75 167-167zm-209-83c67 0 121 50 125 117v341c0 67-50 121-116 125h-342c-67 0-121-50-125-116v-342c0-67 50-121 117-125z"/>
                    </svg>
                </Icon>
                <Icon zIndex={1000} position="absolute"
                      top="0"
                      right="0"
                      visibility={media.is_video ? "visible" : "hidden"}
                      boxSize={10}
                      m={2}
                      viewBox='0 0 60 60'>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px">
                        <path
                            fill="#fff"
                            d="M 15 4 C 8.9365932 4 4 8.9365932 4 15 L 4 35 C 4 41.063407 8.9365932 46 15 46 L 35 46 C 41.063407 46 46 41.063407 46 35 L 46 15 C 46 8.9365932 41.063407 4 35 4 L 15 4 z M 16.740234 6 L 27.425781 6 L 33.259766 16 L 22.574219 16 L 16.740234 6 z M 29.740234 6 L 35 6 C 39.982593 6 44 10.017407 44 15 L 44 16 L 35.574219 16 L 29.740234 6 z M 14.486328 6.1035156 L 20.259766 16 L 6 16 L 6 15 C 6 10.199833 9.7581921 6.3829803 14.486328 6.1035156 z M 6 18 L 44 18 L 44 35 C 44 39.982593 39.982593 44 35 44 L 15 44 C 10.017407 44 6 39.982593 6 35 L 6 18 z M 21.978516 23.013672 C 20.435152 23.049868 19 24.269284 19 25.957031 L 19 35.041016 C 19 37.291345 21.552344 38.713255 23.509766 37.597656 L 31.498047 33.056641 C 33.442844 31.951609 33.442844 29.044485 31.498047 27.939453 L 23.509766 23.398438 L 23.507812 23.398438 C 23.018445 23.120603 22.49297 23.001607 21.978516 23.013672 z M 21.982422 24.986328 C 22.158626 24.988232 22.342399 25.035052 22.521484 25.136719 L 30.511719 29.677734 C 31.220922 30.080703 31.220922 30.915391 30.511719 31.318359 L 22.519531 35.859375 C 21.802953 36.267773 21 35.808686 21 35.041016 L 21 25.957031 C 21 25.573196 21.201402 25.267385 21.492188 25.107422 C 21.63758 25.02744 21.806217 24.984424 21.982422 24.986328 z"></path>
                    </svg>
                </Icon>

            </>
            <Box mb={1}>
                <AspectRatio onClick={onClick} ratio={media.product_type === "story" ? (9 / 16) : (3 / 4)}>
                    <Image src={convertThumb(media?.thumbnail_url)}/>
                </AspectRatio>
                <Link isExternal download="true"
                      href={convertThumb(media.is_video ? media.video_url : media?.image_versions.items[0].url)}>
                    <Button size={{base: "sm", md: "md"}} mt={2} colorScheme="purple" width="full">Download</Button>
                </Link>
            </Box>
        </Box>
    )
}

export default Media