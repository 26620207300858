import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle, Button, Grid, GridItem, Link, Text
} from "@chakra-ui/react";


function Private() {
    return (
        <Alert
            status='warning'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
        >
            <AlertIcon boxSize='40px' mr={0}/>
            <AlertTitle mt={4} mb={1} fontSize='lg'>
                The account is private
            </AlertTitle>
            <AlertDescription>
                <Text fontSize='sm'>
                    For viewing private account stories use our apps:
                </Text>
                <Grid
                    templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                    gap={4}
                    mt={6}
                >
                    <GridItem>
                        <Link
                            isExternal
                            href="https://play.google.com/store/apps/details?id=com.getghostify&pli=1&utm_source=getghostify.com_prv&utm_medium=website&utm_campaign=ghostify%20website"
                            w="100%"
                        >
                            <Button size={["sm", "md"]} colorScheme='purple' w="100%">
                                Download Android App
                            </Button>
                        </Link>
                    </GridItem>
                    <GridItem>
                        <Link
                            isExternal
                            href="https://chromewebstore.google.com/detail/ghostify-instagram-story/ajkkboilpcbfgojiochgfbeblbpcgpga?utm_source=getghostify.com_prv&utm_medium=website&utm_campaign=ghostify%20website"
                            w="100%"
                        >
                            <Button size={["sm", "md"]} colorScheme='purple' w="100%">
                                Download Chrome Extension
                            </Button>
                        </Link>
                    </GridItem>
                </Grid>
            </AlertDescription>
        </Alert>
)
}

export default Private